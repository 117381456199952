import * as React from 'react';
import { SVGProps } from 'react';

const SvgMagnifyingGlass = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 14.5c1.57 0 3.02-.53 4.18-1.4l4.11 4.11 1.41-1.41-4.11-4.11c.88-1.17 1.4-2.61 1.4-4.18 0-3.86-3.14-7-7-7s-7 3.14-7 7S3.64 14.5 7.5 14.5Zm0-12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5Z"
      fill="#000"
      fillOpacity={0.9}
    />
  </svg>
);
export default SvgMagnifyingGlass;
