import type { MarketListCustomEvent } from '@market/web-components/loader';
import { ChevronDown } from '@squareup/dex-icons/dex/control';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Paragraph20 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import {
  MarketCheckbox,
  MarketDropdown,
  MarketList,
  MarketPopover,
  MarketRow,
  MarketButton,
} from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import styles from './search-filter.module.css';

type SearchFilterSelectionState = 'all' | 'multiple' | 'single';

interface SearchFilterProps {
  categories: string[];
  selectedCategories: string[];
  onCategoriesChanged: (categories: string[]) => void;
}

interface SearchFilterRowProps {
  value: string;
  controlRow?: boolean | undefined;
}

const allResultsLabel = 'All results';

const SearchFilterRow: FunctionComponent<SearchFilterRowProps> = ({
  value,
  controlRow,
}) => {
  // Bit of a hack to avoid setting the slot to "undefined"
  const props: { slot?: string | undefined } = {};
  if (controlRow) {
    // This will be used when the "control-row" for select all
    // becomes available to market
    props.slot = 'control-row';
  }

  return (
    <MarketRow
      value={value}
      size={'small'}
      {...props}
      testId={`search-filter-row-${value}`}
      trackingId={`search-filter-row-${value}`}
    >
      <label slot="label">
        <Paragraph20 weight={'semi-bold'}>{value}</Paragraph20>
      </label>
      <MarketCheckbox slot="control"></MarketCheckbox>
    </MarketRow>
  );
};

const SearchFilter = React.forwardRef<
  globalThis.HTMLMarketDropdownElement,
  SearchFilterProps & NullableClassName
>(({ categories, className, onCategoriesChanged, selectedCategories }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const onCategoryChanged = useCallback(
    (e: MarketListCustomEvent<{ currentSelectionValues: string[] }>) => {
      const { currentSelectionValues } = e.detail;

      // Market's onMarketListSelectionsDidChange also returns the control-row value (All results)
      // We don't need to track this ourselves, so slicing it off if it exists
      const [firstValue, ...subsequentValues] = currentSelectionValues;

      onCategoriesChanged(
        firstValue === allResultsLabel
          ? subsequentValues
          : currentSelectionValues
      );
    },
    [onCategoriesChanged]
  );

  const onDropdownOpened = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onDropdownClosed = useCallback(() => {
    setIsOpen(false);
  }, []);

  const filterSelectionState: SearchFilterSelectionState = useMemo(() => {
    const totalCategories = categories.length;
    if (
      selectedCategories.length === 0 ||
      selectedCategories.length === totalCategories
    ) {
      return 'all';
    } else if (selectedCategories.length === 1) {
      return 'single';
    } else {
      // Between 0 and less than total
      return 'multiple';
    }
  }, [selectedCategories, categories]);

  const filterLabel = useMemo(() => {
    switch (filterSelectionState) {
      case 'all':
        return 'Filter';
      case 'single':
        return selectedCategories[0];
      case 'multiple':
        return `Filter (${selectedCategories.length})`;
      default:
        return 'Filter';
    }
  }, [filterSelectionState, selectedCategories]);

  return (
    <MarketDropdown
      className={clsx(styles.dropdown, className)}
      ref={ref}
      interaction={'persistent'}
      popoverDistance={8}
      popoverSkidding={0}
      popoverPlacement="bottom-end"
      onMarketDropdownOpened={onDropdownOpened}
      onMarketDropdownClosed={onDropdownClosed}
    >
      <MarketButton
        slot="trigger"
        rank="tertiary"
        testId="search-filter-button"
        trackingId="search-filter-button"
        className={clsx(
          styles.trigger,
          filterSelectionState !== 'all' && styles.selected
        )}
      >
        <Paragraph20
          as="span"
          weight={'medium'}
          margin={{ right: '0.5x' }}
          colorVariant={'20'}
          className={clsx(filterSelectionState !== 'all' && styles.selected)}
        >
          {filterLabel}
        </Paragraph20>
        <ChevronDown
          className={clsx(
            commonIconStyles['icon-fill-current-color'],
            styles.icon,
            isOpen && styles.open
          )}
        />
      </MarketButton>
      <MarketPopover slot="popover">
        <MarketList
          interactive
          multiselect
          value={selectedCategories.join(',')}
          onMarketListSelectionsDidChange={onCategoryChanged}
        >
          <SearchFilterRow value={allResultsLabel} controlRow={true} />
          {categories.map((category: string) => {
            return <SearchFilterRow key={category} value={category} />;
          })}
        </MarketList>
      </MarketPopover>
    </MarketDropdown>
  );
});

SearchFilter.displayName = 'SearchFilter';

export { SearchFilter };
