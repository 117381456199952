import * as ElasticAppSearch from '@elastic/app-search-javascript';
import { SearchRecord } from '@squareup/dex-types-shared-search';
import Cookies from 'js-cookie';

import { searchDomainDisplayStrings, globalSearchCategories } from '../../tags';
import {
  ISearchEngine,
  SearchQueryOptions,
  SearchResult,
} from '../search-engine';

const IN_DOMAIN_RESULT_SIZE = 6;
const ALL_OTHER_DOMAIN_RESULT_SIZE = 25;
const DEVS_CONTENT_SEARCH_API_URL = '/api/devs-content/search';
const CSRF_COOKIE_NAME = '_js_csrf';
const CSRF_HEADER_NAME = 'X-CSRF-Token';

type ElasticFilters = {
  all?: object[];
  none?: object[];
};

type ElasticSearchOptions = {
  page: object;
  group: object;
  result_fields: Record<string, object>;
  filters?: object;
};

type ElasticSearchArgs = {
  query: string;
  options: ElasticSearchOptions;
};

class GlobalSearchEngine extends ISearchEngine {
  // This is definitely assigned in the constructor, but typescript disagrees
  private engine!: ElasticAppSearch.Client;
  private hostname: string;
  public initialized = false;

  constructor(private env: globalThis.EnvironmentName) {
    super();
    this.buildEngine();
    this.hostname = this.getHostname(env);
  }

  getCategories(): string[] {
    const categories: Set<string> = new Set();
    Object.values(globalSearchCategories).forEach((domainCategories) => {
      Object.values(domainCategories).forEach((domainCategory) => {
        categories.add(domainCategory);
      });
    });
    return [...categories].sort();
  }

  async getPopularResults(): Promise<SearchResult[]> {
    const url = `${this.hostname}${DEVS_CONTENT_SEARCH_API_URL}/recommended-records`;
    const fetchResponse = await fetch(url);
    if (!fetchResponse.ok) {
      throw this.createErrorFromResponse(fetchResponse, 'getPopularResults');
    }

    const recommended: SearchRecord[] = await fetchResponse.json();
    return this.convertSearchRecordsToSearchResults(recommended);
  }

  async getRecentResults(): Promise<SearchResult[]> {
    const url = `${this.hostname}${DEVS_CONTENT_SEARCH_API_URL}/recent-records`;

    const fetchResponse = await fetch(url, {
      credentials: 'include',
    });
    if (!fetchResponse.ok) {
      // 401 is from missing person token which can happen if user is not signed in
      if (fetchResponse.status !== 401) {
        throw this.createErrorFromResponse(fetchResponse, 'getRecentResults');
      }
      return [];
    }
    const recent: SearchRecord[] = await fetchResponse.json();
    return this.convertSearchRecordsToSearchResults(recent);
  }

  async addRecentResult(result: SearchResult): Promise<void> {
    const url = `${this.hostname}${DEVS_CONTENT_SEARCH_API_URL}/recent-records`;
    const fetchOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(result),
    };
    const csrfCookie = Cookies.get(CSRF_COOKIE_NAME);
    if (csrfCookie) {
      fetchOptions.headers = {
        [CSRF_HEADER_NAME]: csrfCookie,
      };
    }

    const fetchResponse = await fetch(url, fetchOptions);
    if (!fetchResponse.ok) {
      // 401 is from missing person token which can happen if user is not signed in
      if (fetchResponse.status !== 401) {
        throw this.createErrorFromResponse(fetchResponse, 'addRecentResult');
      }
    }
  }

  async query(
    query: string,
    options?: SearchQueryOptions
  ): Promise<SearchResult[]> {
    if (query === '') {
      return [];
    }

    const queries: ElasticSearchArgs[] = [];
    if (options?.domain) {
      const domain = searchDomainDisplayStrings[options.domain];
      const inDomainQueryArgs = this.getElasticSearchQueryArgs(
        query,
        IN_DOMAIN_RESULT_SIZE,
        this.getInDomainElasticQueryFilter(domain, options.categoryFilter)
      );
      const otherDomainQueryArgs = this.getElasticSearchQueryArgs(
        query,
        ALL_OTHER_DOMAIN_RESULT_SIZE,
        this.getOutOfDomainElasticQueryFilter(domain, options.categoryFilter)
      );
      queries.push(inDomainQueryArgs, otherDomainQueryArgs);
    } else {
      const noDomainQueryArgs = this.getElasticSearchQueryArgs(
        query,
        ALL_OTHER_DOMAIN_RESULT_SIZE,
        options?.categoryFilter && options.categoryFilter.length > 0
          ? this.getNoDomainElasticQueryFilter(options.categoryFilter)
          : undefined
      );
      queries.push(noDomainQueryArgs);
    }

    const responses = await this.engine.multiSearch(queries);
    return this.convertRawResultsIntoSearchResults(
      responses.flatMap((resp) => resp.rawResults)
    );
  }

  private getHostname(env: globalThis.EnvironmentName) {
    if (env === 'production') {
      return 'https://developer.squareup.com';
    } else {
      return 'https://developer.squareupstaging.com';
    }
  }

  private createErrorFromResponse(
    response: Response,
    functionName: string
  ): Error {
    return new Error(
      `${functionName} error status: ${response.status}; statusText: ${response.statusText}`
    );
  }

  private convertSearchRecordsToSearchResults(
    searchRecords: SearchRecord[]
  ): SearchResult[] {
    return searchRecords.map((result) => ({ record: result }));
  }

  private convertRawResultsIntoSearchResults(
    results: object[]
  ): SearchResult[] {
    return this.convertSearchRecordsToSearchResults(
      results
        .map((result) => {
          // convert result from `{key: {raw: value}}` to `{key: value}`
          const record: SearchRecord = Object.fromEntries(
            Object.entries(result)
              .filter(([_, v]) => v.raw)
              .map(([k, v]) => [k, v.raw])
          ) as SearchRecord;
          return record;
        })
        .filter(
          (result) =>
            result.id &&
            result.name &&
            result.url &&
            result.display_category &&
            result.domain
        )
    );
  }

  private getInDomainElasticQueryFilter(
    domain: string,
    categoryFilter?: string[]
  ): ElasticFilters {
    const filters: ElasticFilters = {
      all: [{ domain: [domain] }],
    };
    if (categoryFilter && categoryFilter.length > 0) {
      filters.all?.push({ display_category: categoryFilter });
    }
    return filters;
  }

  private getOutOfDomainElasticQueryFilter(
    excludedDomain: string,
    categoryFilter?: string[]
  ): ElasticFilters {
    const filters: ElasticFilters = {
      none: [{ domain: [excludedDomain] }],
    };
    if (categoryFilter && categoryFilter.length > 0) {
      filters.all = [{ display_category: categoryFilter }];
    }
    return filters;
  }

  private getNoDomainElasticQueryFilter(
    categoryFilter: string[]
  ): ElasticFilters {
    return {
      all: [{ display_category: categoryFilter }],
    };
  }

  private getElasticSearchQueryArgs(
    query: string,
    numResults: number,
    elasticFilters?: ElasticFilters
  ): ElasticSearchArgs {
    const options: ElasticSearchOptions = {
      page: { size: numResults },
      result_fields: {},
      group: { field: 'url' },
    };

    if (elasticFilters) {
      options.filters = elasticFilters;
    }

    const searchRecordKeys: (keyof SearchRecord)[] = [
      'id',
      'name',
      'description',
      'url',
      'domain',
      'parents',
      'display_category',
      'categories',
    ];
    for (const key of searchRecordKeys) {
      options.result_fields[key] = { raw: {} };
    }

    return { query, options };
  }

  private buildEngine() {
    this.engine = ElasticAppSearch.createClient({
      searchKey: 'search-civwptmkan3w22g26funn3th',
      endpointBase: 'https://global-search.ent.us-west-2.aws.found.io',
      engineName: `global-search-engine-${this.env}`,
    });
    this.initialized = true;
  }
}

export { GlobalSearchEngine };
