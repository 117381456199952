import XIcon from '@square-icons/react/16/UI/x';
import { Box } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { MarketDialog, MarketButton } from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { ISearchEngine, SearchResult } from '../../engine/base/search-engine';
import { SearchDomain } from '../../search-types';
import { SearchEmptyView } from '../SearchEmptyView/SearchEmptyView';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchLinkComponent } from '../SearchItem/SearchItem';
import { SearchKeyboardControlsBox } from '../SearchKeyboardControlsBox/SearchKeyboardControlsBox';
import { SearchKeyboardManager } from '../SearchKeyboardManager/SearchKeyboardManager';
import { SearchResults } from '../SearchResults/SearchResults';

import { getSearchResultsInSections } from './search-helpers';
import styles from './search.module.css';

interface SearchProps {
  isOpen: boolean;
  searchEngine: ISearchEngine;
  onCloseAction: () => void;
  domain?: SearchDomain;
  // An injectable LinkComponent. Mainly used if the framework like
  // nextjs wants to run client-side routing. If not available, use
  // a normal anchor tag.
  LinkComponent?: SearchLinkComponent | undefined;
  isSignedIn?: boolean;
  errorLogger: (error: Error) => void;
  onEngineQuery: (
    query: string,
    results: SearchResult[],
    filters: string[]
  ) => void;
}

// Ignore this for now. Will make better in future PR.
// Just testing out how the UI would work with the engine
const Search: FunctionComponent<SearchProps> = ({
  isOpen,
  searchEngine,
  onCloseAction,
  domain,
  LinkComponent,
  isSignedIn,
  errorLogger,
  onEngineQuery,
}) => {
  const [inputValue, setInputValue] = useState('');

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  // We need to remember the difference between the initial empty state,
  // and a "no results" state.
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);

  const [hasShim, setHasShim] = useState(false);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchFiltersRef = useRef<globalThis.HTMLMarketDropdownElement>(null);
  const searchDialogRef = useRef<globalThis.HTMLMarketDialogElement>(null);

  const searchCategoriesMemo = useMemo(() => {
    return searchEngine.getCategories();
  }, [searchEngine]);

  const [currentFilters, setCurrentFilters] =
    useState<string[]>(searchCategoriesMemo);
  const [recentSearches, setRecentSearches] = useState<SearchResult[]>([]);
  const [popularSearches, setPopularSearches] = useState<SearchResult[]>([]);
  const DEBOUNCE_WAIT_MS = 100;

  // Keeps track of whether or no the focus is on a result
  const [resultFocusedOn, setResultFocusedOn] = useState<boolean>(false);

  useEffect(() => {
    setCurrentFilters(searchCategoriesMemo);
  }, [searchCategoriesMemo]);

  useEffect(() => {
    const fetchAndSetInitialResults = async () => {
      if (isSignedIn) {
        const [popular, recent] = await Promise.all([
          searchEngine.getPopularResults().catch((error) => {
            errorLogger(error);
            return [];
          }),
          searchEngine.getRecentResults().catch((error) => {
            errorLogger(error);
            return [];
          }),
        ]);
        if (popular.length > 0) {
          setPopularSearches(popular);
        }
        if (recent.length > 0) {
          setRecentSearches(recent);
        }
      } else {
        const popular = await searchEngine.getPopularResults();
        if (popular.length > 0) {
          setPopularSearches(popular);
        }
      }
    };
    fetchAndSetInitialResults().catch((error) => errorLogger(error));
  }, [isSignedIn, errorLogger, searchEngine]);

  const queryEngine = (input: string) => {
    searchEngine
      .query(input, {
        categoryFilter: currentFilters,
        ...(domain && { domain }),
      })
      .then((results) => {
        setIsSearchError(false);
        setSearchResults(results);
        const telemetryFilters =
          currentFilters.length === searchCategoriesMemo.length
            ? ['All']
            : currentFilters;
        onEngineQuery(input, results, telemetryFilters);
      })
      .catch((error) => {
        setIsSearchError(true);
        setSearchResults([]);
        errorLogger(error as Error);
      })
      .finally(() => {
        setHasSearched(true);
      });
  };

  const handleDebounce = useDebouncedCallback((input: string) => {
    queryEngine(input);
  }, DEBOUNCE_WAIT_MS);

  useEffect(() => {
    if (inputValue.length > 0) {
      handleDebounce(inputValue);
    } else {
      setHasSearched(false);
      setIsSearchError(false);
      setSearchResults([]);
    }
  }, [inputValue, searchEngine, currentFilters, domain, handleDebounce]);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  // Logic for adding an overflow shim when search results grow too big
  useEffect(() => {
    const currentSearchResultsRef = searchResultsRef.current;

    // TODO: Use debounce(check bundle size if we are pulling in lodash to do this), RIC and RAF as this uses scrollHeight adn offsetHeight which will force a layout
    // https://luisball.com/request-animation-frame-versus-request-idle-callback/
    function onScroll() {
      if (!currentSearchResultsRef) {
        return;
      }
      const roundedTotal = Math.ceil(
        currentSearchResultsRef.scrollTop + currentSearchResultsRef.offsetHeight
      );

      if (roundedTotal >= currentSearchResultsRef.scrollHeight) {
        setHasShim(false);
      } else {
        setHasShim(true);
      }
    }

    onScroll();

    currentSearchResultsRef?.addEventListener('scroll', onScroll, {
      passive: true,
    });

    return () => {
      currentSearchResultsRef?.removeEventListener('scroll', onScroll);
    };
  }, [searchResultsRef, searchResults]);

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setHasShim(false);
    }
  }, [isOpen]);

  const showNoResultsFound =
    searchResults.length === 0 &&
    hasSearched &&
    inputValue.length > 0 &&
    !isSearchError;
  const showInitialResults =
    inputValue.length === 0 &&
    (recentSearches.length > 0 || popularSearches.length > 0);

  const getDisplayResults = () => {
    if (showInitialResults) {
      const title =
        recentSearches.length > 0 ? 'Recent searches' : 'Popular searches';
      const results =
        recentSearches.length > 0 ? recentSearches : popularSearches;
      return [{ title, results }];
    }

    return getSearchResultsInSections(searchResults, domain);
  };

  const addRecentResult = useCallback(
    async (result: SearchResult) => {
      try {
        await searchEngine.addRecentResult(result);
        const results = await searchEngine.getRecentResults();
        if (results.length > 0) {
          setRecentSearches(results);
        }
      } catch (error) {
        errorLogger(error as Error);
      }
    },
    [errorLogger, searchEngine]
  );

  const onFocusHandler = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      if (event.target === searchInputRef.current) {
        setResultFocusedOn(false);
      } else {
        setResultFocusedOn(true);
      }
    },
    [setResultFocusedOn]
  );

  const onBlurHandler = useCallback(() => {
    setResultFocusedOn(true); // Deactivates default when not focused on.
  }, [setResultFocusedOn]);

  const onMouseEnterResults = useCallback(
    () => setResultFocusedOn(true),
    [setResultFocusedOn]
  );
  const onMouseLeaveResults = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // Do not set default if focus is currently on a result
      if (!event.currentTarget.contains(document.activeElement)) {
        setResultFocusedOn(false);
      }
    },
    [setResultFocusedOn]
  );

  return isOpen ? (
    <MarketDialog
      ref={searchDialogRef}
      className={clsx(styles.dialog)}
      onMarketDialogDismissed={onCloseAction}
    >
      <Box
        ref={searchContainerRef}
        className={clsx(styles.container)}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      >
        <Box
          className={clsx(styles['top-container'], styles.background)}
          padding={{ horizontal: '3x', vertical: '3x' }}
        >
          <Box
            className={styles['mobile-close-button']}
            margin={{ right: '1x' }}
          >
            <MarketButton
              rank="secondary"
              size="small"
              onClick={onCloseAction}
              testId="search-mobile-close-button"
              trackingId="search-mobile-close-button"
            >
              <XIcon slot="icon" className={commonIconStyles['icon-color']} />
            </MarketButton>
          </Box>
          <SearchInput
            className={styles.input}
            ref={searchInputRef}
            inputValue={inputValue}
            onInputChanged={onInputChange}
          />
          <SearchFilter
            className={styles.filter}
            ref={searchFiltersRef}
            categories={searchCategoriesMemo}
            selectedCategories={currentFilters}
            onCategoriesChanged={setCurrentFilters}
          />
        </Box>
        <Box
          className={clsx(styles['bottom-container'], styles.background)}
          border={{
            line: { top: 'standard' },
          }}
          // Results are dynamic in this container, so set it to polite
          aria-live="polite"
        >
          {(showInitialResults || searchResults.length > 0) && (
            <SearchResults
              ref={searchResultsRef}
              onClick={onCloseAction}
              hasShim={hasShim}
              results={getDisplayResults()}
              addRecentResult={isSignedIn ? addRecentResult : undefined}
              LinkComponent={LinkComponent}
              noneFocused={!resultFocusedOn}
              onMouseEnterResults={onMouseEnterResults}
              onMouseLeaveResults={onMouseLeaveResults}
            />
          )}
          {showNoResultsFound && (
            <SearchEmptyView
              primaryText="No results found."
              secondaryText="Try searching with a different term."
              testId="no-search-results"
            />
          )}
          {isSearchError && (
            <SearchEmptyView
              primaryText="Search not available."
              secondaryText="Sorry, please try again later."
              testId="error-search-results"
            />
          )}

          <Box margin={{ horizontal: '2x', vertical: '2x' }}>
            <SearchKeyboardControlsBox className={styles['hide-mobile']} />
          </Box>
        </Box>
      </Box>
      <SearchKeyboardManager
        inputRef={searchInputRef}
        searchResultsRef={searchResultsRef}
        searchContainerRef={searchContainerRef}
        searchDialogRef={searchDialogRef}
        searchFiltersRef={searchFiltersRef}
        closeSearch={onCloseAction}
      />
    </MarketDialog>
  ) : null;
};

export { Search };
