/* eslint-disable custom-rules/prefer-dex-ui-to-native */
import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React from 'react';

import { SearchResult } from '../../engine/base/search-engine';
import { SearchItem, SearchLinkComponent } from '../SearchItem/SearchItem';

import styles from './search-results.module.css';

interface SearchResultSection {
  title?: string;
  results: SearchResult[];
}
interface SearchResultsProps {
  onClick: () => void;
  hasShim: boolean;
  results: SearchResultSection[];
  addRecentResult?: ((result: SearchResult) => void) | undefined;
  LinkComponent?: SearchLinkComponent | undefined;
  noneFocused: boolean;
  onMouseEnterResults: () => void;
  onMouseLeaveResults: (event: React.MouseEvent<HTMLElement>) => void;
}

const SearchResults = React.forwardRef<HTMLElement, SearchResultsProps>(
  (
    {
      onClick,
      addRecentResult,
      hasShim,
      results,
      LinkComponent,
      noneFocused,
      onMouseEnterResults,
      onMouseLeaveResults,
    },
    ref
  ) => {
    let numResultsAcc = 0;
    return (
      <Box
        ref={ref}
        className={clsx(styles.results, hasShim && styles.shim)}
        onClick={onClick}
        padding={{ top: '1x' }}
        onMouseEnter={onMouseEnterResults}
        onMouseLeave={onMouseLeaveResults}
      >
        {results.map((section, sectionIndex) => {
          const rankOffset = numResultsAcc;
          numResultsAcc += section.results.length;
          return (
            <div
              key={
                section.title
                  ? section.title
                  : `section${section.results[0]?.record.id}`
              }
              className={sectionIndex > 0 ? styles.divided : undefined}
            >
              {section.title && (
                <Paragraph10
                  padding={{
                    horizontal: '2x',
                    top: '2x',
                  }}
                  margin={{ horizontal: '2x' }}
                  colorVariant="20"
                >
                  {section.title}
                </Paragraph10>
              )}
              {section.results.map((result, resultIndex) => {
                return (
                  <SearchItem
                    key={result.record.id}
                    record={result.record}
                    rank={rankOffset + resultIndex + 1}
                    applyFocusStyleIfNoneFocused={
                      noneFocused && sectionIndex === 0 && resultIndex === 0
                    }
                    onResultClicked={() => {
                      addRecentResult?.(result);
                    }}
                    LinkComponent={LinkComponent}
                  />
                );
              })}
            </div>
          );
        })}
      </Box>
    );
  }
);

SearchResults.displayName = 'SearchResults';

export { SearchResults, type SearchResultSection };
