/**
 * In some cases, we want to strip out all the markdown from a file,
 * and simply return basic text. This will strip anchors, bolding, italics,
 * and more.
 * @param markdown The markdown the strip
 * @returns Normal text without markdown
 */
function stripMarkdown(markdown: string): string {
  if (!markdown) {
    return '';
  }
  const replacer = (_: string, p1?: string) => (p1 ? p1 : '');

  let stripped = markdown;

  // Strip **bold** or *italics* to plain text
  stripped = stripped.replace(/\*{1,2}(.+?)\*{1,2}/g, replacer);

  // Strip __bold__ or _italics_ to plain text
  stripped = stripped.replace(/_{1,2}(.+?)_{1,2}/g, replacer);

  // Strip [text](link) to text
  stripped = stripped.replace(/\[(.+?)]\((.+?)\)/g, replacer);

  // Strip `code blocks` or ``` big code blocks ``` to plain text
  stripped = stripped.replace(/`{1,3}/g, '');

  return stripped;
}

/**
 * Bypass transforms for code blocks by replacing with temp string
 * ```<p>hi</p>``` -> cb{0}
 */
function bypassCodeBlocks(content: string): {
  result: string;
  blocks: string[];
} {
  let result = content;

  const codeBlockRegex = /```[\S\s]*?```/;
  const blocks: string[] = [];
  let currentMatch: RegExpMatchArray | null;

  let blockIndex = 0;
  while ((currentMatch = result.match(codeBlockRegex))) {
    result = result.replace(codeBlockRegex, `cb{${blockIndex}}`);
    const m = currentMatch[0];
    blocks.push(m);
    blockIndex++;
  }

  return { result, blocks };
}

/**
 * Restore code block text from temp bypass string
 * cb{0} -> ```<p>hi</p>```
 */
function restoreCodeBlocks(content: string, blocks: string[]) {
  const bypassedBlockRegex = /cb{\d+}/;
  return blocks.reduce(
    (result, block) => result.replace(bypassedBlockRegex, block),
    content
  );
}

export { stripMarkdown, bypassCodeBlocks, restoreCodeBlocks };
