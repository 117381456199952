/* eslint-disable @typescript-eslint/no-restricted-imports */
import { SharedStoreState } from '@squareup/dex-shell-main-shared';
import { useSelector } from 'react-redux';

const selectMobileNavState = (state: SharedStoreState) => {
  return state.sharedShellViewState.mobileNav;
};

const selectLeftNavState = (state: SharedStoreState) => {
  return state.sharedShellViewState.leftNav;
};

const selectErrorState = (state: SharedStoreState) => {
  return state.sharedShellViewState.errors;
};

const selectSearchState = (state: SharedStoreState) => {
  return state.sharedShellViewState.search;
};

const selectGlobalBannerState = (state: SharedStoreState) => {
  return state.sharedShellViewState.globalBanner;
};

const selectCodeLanguage = (state: SharedStoreState) => {
  return state.sharedShellViewState.codeLanguage;
};

// React Selector Hooks
const useSelectMobileNavState = () => useSelector(selectMobileNavState);
const useSelectLeftNavState = () => useSelector(selectLeftNavState);
const useSelectErrorState = () => useSelector(selectErrorState);
const useSelectSearchState = () => useSelector(selectSearchState);
const useSelectGlobalBannerState = () => useSelector(selectGlobalBannerState);
const useSelectCodeLanguage = () => useSelector(selectCodeLanguage);

export {
  selectErrorState,
  useSelectMobileNavState,
  useSelectLeftNavState,
  useSelectErrorState,
  useSelectSearchState,
  useSelectGlobalBannerState,
  useSelectCodeLanguage,
};
