import clsx from 'clsx';
import { FC } from 'react';

import styles from './mobile-nav-helpers.module.css';

type MobileNavShifterRenderProps = {
  className: string;
};

type MobileNavShifterProps = {
  isMobileNavOpen: boolean;
  showHamburgerOnTablet?: boolean | undefined;
  render: FC<MobileNavShifterRenderProps>;
};

interface NavigationMobileToggleProps {
  isMobileNavOpen?: boolean | undefined;
}

const MobileNavShifter: FC<MobileNavShifterProps> = ({
  render,
  isMobileNavOpen,
  showHamburgerOnTablet,
}) => {
  return render({
    className: clsx(
      styles.vars,
      styles.shifter,
      isMobileNavOpen && styles['shift-contents'],
      showHamburgerOnTablet && styles['show-hamburger-tablet']
    ),
  });
};

export {
  MobileNavShifter,
  type MobileNavShifterRenderProps,
  type NavigationMobileToggleProps,
};

export { default as mobileNavHelperStyles } from './mobile-nav-helpers.module.css';
