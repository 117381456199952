import { SearchResult } from '../../engine/base/search-engine';
import { searchDomainDisplayStrings } from '../../engine/tags';
import { SearchDomain } from '../../search-types';
import { SearchResultSection } from '../SearchResults/SearchResults';

/* 
  Organizes results in sections based on domain,
  if the domain isn't given or there are no in-domain or out-of-domain 
  results, returns the results with no title.
*/
const getSearchResultsInSections = (
  results: SearchResult[],
  domain?: SearchDomain
) => {
  if (!domain) {
    return [{ results }];
  }

  const res: SearchResultSection[] = [];
  const displayDomain = searchDomainDisplayStrings[domain];
  const inDomainResults = results.filter(
    (result) => result.record.domain === displayDomain
  );
  const outOfDomainResults = results.filter(
    (result) => result.record.domain !== displayDomain
  );

  if (inDomainResults.length > 0 && outOfDomainResults.length > 0) {
    res.push(
      { title: `${displayDomain} results`, results: inDomainResults },
      { title: 'Other results', results: outOfDomainResults }
    );
    return res;
  }

  return [{ results }];
};

export { getSearchResultsInSections };
