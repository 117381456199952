// eslint-disable-next-line no-restricted-imports
import { MarketRow as MarketRowBinding } from '@market/react';
import { MarketBindingWithoutClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import React from 'react';

type RowProps = MarketBindingWithoutClassName<
  React.ComponentProps<typeof MarketRowBinding>
> &
  TestProps &
  Partial<TrackedProps>;

const MarketRow = React.forwardRef<globalThis.HTMLMarketRowElement, RowProps>(
  ({ children, testId, trackingId, trackingExtra, ...rest }, ref) => {
    return (
      // @ts-ignore - Claims className: string | undefined cannot be assigned to string
      // However, this can be passed down successfully
      <MarketRowBinding
        data-testid={testId}
        data-tracking-id={trackingId}
        data-tracking-extra={trackingExtra}
        {...rest}
        ref={ref}
      >
        {children}
      </MarketRowBinding>
    );
  }
);

MarketRow.displayName = 'MarketRow';

export { MarketRow };
