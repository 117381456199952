import * as React from 'react';
import { SVGProps } from 'react';

const SvgSmallChevronRight = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={5}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.793 4 .646.854l.708-.708 3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5-.708-.708L3.793 4Z"
      fill="#000"
    />
  </svg>
);
export default SvgSmallChevronRight;
