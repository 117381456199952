import EnterIcon from '@squareup/dex-icons/dex/arrow/Enter';
import UpDownArrowIcon from '@squareup/dex-icons/dex/arrow/UpDown';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box, BoxProps, Paragraph10 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';

import styles from './search-keyboard-controls-box.module.css';

interface KeyboardControlWithTextProps {
  icon: ReactNode;
  text: string;
}

const KeyboardControlWithText: FunctionComponent<
  KeyboardControlWithTextProps & NullableClassName & BoxProps
> = ({ icon, text, className, ...rest }) => {
  return (
    <Box className={clsx(styles['keyboard-control'], className)} {...rest}>
      {icon}
      <Paragraph10 className={styles['control-text']} margin={{ left: '0.5x' }}>
        {text}
      </Paragraph10>
    </Box>
  );
};

const SearchKeyboardControlsBox: FunctionComponent<NullableClassName> = ({
  className,
}) => {
  return (
    <Box
      className={clsx(styles.container, className)}
      padding={{ horizontal: '2x', vertical: '1x' }}
      border={{ radius: 'standard' }}
    >
      <KeyboardControlWithText
        icon={<UpDownArrowIcon className={commonIconStyles['icon-color-20']} />}
        text={'Move'}
      />
      <KeyboardControlWithText
        icon={
          <EnterIcon className={commonIconStyles['icon-current-color-20']} />
        }
        text={'Select'}
        margin={{ left: '1x' }}
      />
      <KeyboardControlWithText
        className={styles.last}
        icon={
          <Paragraph10
            padding={{ horizontal: '0.5x', top: '0.25x', bottom: '0.5x' }}
            margin={{ right: '0.25x' }}
            className={styles.esc}
            weight={'semi-bold'}
            border={{ radius: '4' }}
          >
            esc
          </Paragraph10>
        }
        text={'Exit'}
      />
    </Box>
  );
};

export { SearchKeyboardControlsBox };
