import { Heading20, Paragraph30 } from '@squareup/dex-ui-shared-base';
import { MarketEmptyState } from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

import styles from './search-empty-view.module.css';

interface SearchEmptyViewProps {
  primaryText: string;
  secondaryText: string;
  testId: string;
}

// Component to capture various empty views of our Search UI.
const SearchEmptyView: FunctionComponent<SearchEmptyViewProps> = ({
  primaryText,
  secondaryText,
  testId,
}) => {
  return (
    <MarketEmptyState
      data-testid={testId}
      className={clsx(styles.emptyresults, styles.background)}
    >
      <Heading20 slot="primary-text">{primaryText}</Heading20>
      <Paragraph30 margin={{ top: '1x' }} slot="secondary-text">
        {secondaryText}
      </Paragraph30>
    </MarketEmptyState>
  );
};

export { SearchEmptyView };
