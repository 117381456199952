import * as React from 'react';
import { SVGProps } from 'react';

const SvgUpDown = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={20}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5601_44014)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.585938 7L5.00015 2.58579L9.41436 7L8.00015 8.41422L6.00015 6.41421L6.00015 13L4.00015 13L4.00015 6.41421L2.00015 8.41422L0.585938 7Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5859 9L15.0002 13.4142L19.4144 9L18.0002 7.58578L16.0002 9.58579L16.0002 3L14.0002 3V9.58579L12.0002 7.58578L10.5859 9Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5601_44014">
        <rect width="20" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUpDown;
