import React, { FunctionComponent, RefObject, useEffect } from 'react';

interface SearchKeyboardManagerProps {
  searchContainerRef: RefObject<HTMLDivElement>;
  inputRef: RefObject<HTMLInputElement>;
  searchResultsRef: RefObject<HTMLDivElement>;
  searchDialogRef: RefObject<globalThis.HTMLMarketDialogElement>;
  searchFiltersRef: RefObject<globalThis.HTMLMarketDropdownElement>;
  closeSearch: () => void;
}

/**
 * Returns true is a string is a printable key in an input.
 * Otherwise, returns false.
 * @param keyEvent The keyevent we're reading
 */
function isPrintableKey(keyEvent: KeyboardEvent): boolean {
  // All keyboard event special keys are actually of length > 1,
  // as seen here - https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
  // Therefore, any key of length 1 is printable.
  if (keyEvent.key.length === 1 && !keyEvent.metaKey) {
    return true;
  }

  // Remaining checks are only for keydown events
  if (keyEvent.type === 'keypress') {
    return false;
  }

  // Select all should go to the input
  if (keyEvent.key === 'a' && keyEvent.metaKey) {
    return true;
    // Delete keys go to the input
  } else if (keyEvent.key === 'Backspace' || keyEvent.key === 'Delete') {
    return true;
  }

  return false;
}

const SearchKeyboardManager: FunctionComponent<SearchKeyboardManagerProps> = ({
  searchContainerRef,
  inputRef,
  searchResultsRef,
  searchDialogRef,
  searchFiltersRef,
  closeSearch,
}) => {
  useEffect(() => {
    const searchContainerElement = searchContainerRef.current;
    if (!searchContainerElement) {
      return () => null;
    }

    function onKeydown(e: KeyboardEvent) {
      /**
       * To determine where to move focus, we will do the following:
       * 1. If the user pressed down
       *    a. If on the input control
       *      i. Go to the first element in the list if it exists. Otherwise...
       *      ii. Do nothing
       *    b. If on a search item except the last
       *      i. Go to the next sibling search item
       *    c. If on the last search item
       *      i. Do nothing
       * 2. If the user pressed up
       *    a. If on the input control
       *      i. Do nothing
       *    b. If on a search item except the first
       *      i. Go to the previous sibling search item
       *    c. If on the first search item
       *      i. Go to the input control
       */
      const activeElement = document.activeElement;
      const anchors = searchResultsRef.current?.getElementsByTagName('a');
      const isFirstSearchItemActiveElement =
        anchors && anchors[0] === activeElement;
      const isLastSearchItemActiveElement =
        anchors && anchors[anchors.length - 1] === activeElement;
      const isSearchResult = searchResultsRef.current?.contains(activeElement);
      const dropDownButton = searchFiltersRef.current?.children.item(
        0
      ) as globalThis.HTMLMarketButtonElement;

      const filterPopOver = searchFiltersRef.current?.children.item(
        1
      ) as globalThis.HTMLMarketButtonElement;

      const filterOptions = filterPopOver?.getElementsByTagName('market-row');

      function onArrowDown() {
        if (
          activeElement === inputRef.current &&
          anchors &&
          anchors.length > 0
        ) {
          (anchors[0] as HTMLAnchorElement)?.focus();
        } else if (isSearchResult && !isLastSearchItemActiveElement) {
          if (activeElement?.nextElementSibling) {
            (activeElement.nextElementSibling as HTMLAnchorElement).focus();
            return;
          }

          const currentSection = activeElement?.parentElement;
          const nextSectionAnchors =
            currentSection?.nextElementSibling?.getElementsByTagName('a');
          if (nextSectionAnchors && nextSectionAnchors.length > 0) {
            (nextSectionAnchors[0] as HTMLAnchorElement).focus();
          }
        }
      }

      function onArrowUp() {
        if (searchResultsRef.current?.contains(activeElement)) {
          if (isFirstSearchItemActiveElement) {
            inputRef.current?.focus();
          } else {
            if (
              activeElement?.previousElementSibling &&
              activeElement?.previousElementSibling.tagName.toLocaleLowerCase() ===
                'a'
            ) {
              (
                activeElement.previousElementSibling as HTMLAnchorElement
              ).focus();
              return;
            }

            const currentSection = activeElement?.parentElement;
            const previousSectionAnchors =
              currentSection?.previousElementSibling?.getElementsByTagName('a');
            if (previousSectionAnchors && previousSectionAnchors.length > 0) {
              (
                previousSectionAnchors[
                  previousSectionAnchors.length - 1
                ] as HTMLAnchorElement
              ).focus();
            }
          }
        }
      }

      function onTab() {
        // If focus is on input set focus to filters dropdown
        if (activeElement === inputRef.current) {
          // eslint-disable-next-line no-void
          void dropDownButton.setFocus(true);
          e.preventDefault();
          return;
        }

        // if focus is on closed filters dropdown, set focus to search item or input if there are no search items
        if (dropDownButton.focused && !searchFiltersRef.current?.expanded) {
          const focusableElement = anchors?.item(0) || inputRef.current;
          focusableElement?.focus();
          e.preventDefault();
          return;
        }

        // if focused is on open filters dropdown, set focus to the first filter
        const firstFilter = filterOptions.item(0);
        if (
          dropDownButton.focused &&
          searchFiltersRef.current?.expanded &&
          firstFilter
        ) {
          firstFilter?.focus();
          e.preventDefault();
          return;
        }

        // If focus is on the last filter set focus to input.
        const lastFilter = filterOptions.item(filterOptions.length - 1);
        // const lastFilterCheckMark = lastFilter?.children.item(1);
        if (lastFilter === activeElement) {
          inputRef.current?.focus();
          e.preventDefault(); // Prevent tab from focusing away from
          return;
        }

        // If focus is within the search results list we want to copy the arrow down functionality.
        if (searchResultsRef.current?.contains(activeElement)) {
          onArrowDown();
          e.preventDefault();
        }
      }

      function onShiftedTab() {
        // If focused element is input don't do anything, so we maintain focus within search
        if (activeElement === inputRef.current) {
          e.preventDefault();
          return;
        }

        // If focus is on dropdown button set focus to input
        if (dropDownButton.focused) {
          inputRef.current?.focus();
          e.preventDefault();
          return;
        }

        // If focus is on the first filter bring focus back to filters dropdown.
        const firstFilter = filterOptions.item(0);
        if (firstFilter === activeElement) {
          // eslint-disable-next-line no-void
          void dropDownButton.setFocus(true); // from input
          e.preventDefault(); // Prevent tab from focusing away from
          return;
        }

        // If within the search results list we want to copy the arrow up functionality.
        if (searchResultsRef.current?.contains(activeElement)) {
          onArrowUp();
          e.preventDefault();
        }
      }

      switch (e.key) {
        case 'ArrowDown':
          onArrowDown();
          e.preventDefault();
          break;
        case 'ArrowUp':
          onArrowUp();
          e.preventDefault();
          break;
        case 'Tab':
          // We're going to hijack, and prevent it from focusing to areas we don't want
          if (e.shiftKey) {
            onShiftedTab();
          } else {
            onTab();
          }
          break;
        case 'Enter':
          if (activeElement === inputRef.current) {
            // When active element is the search input retrieve first anchor and click it.
            anchors?.item(0)?.click();
          }
          break;
        default:
          onKeyEventFocusInput(e);
          break;
      }
    }

    function onKeyEventFocusInput(e: KeyboardEvent) {
      const activeElement = document.activeElement;
      const isSearchResult = searchResultsRef.current?.contains(activeElement);

      if (!isSearchResult) {
        return;
      }

      if (!inputRef.current) {
        return;
      }

      if (isPrintableKey(e)) {
        inputRef.current.focus();
      }
    }

    function onClick(e: MouseEvent) {
      const target = e.target as HTMLElement;
      // This is a workaround because we can't set `align: start` on the
      // market dialog. If we set it, then clicking right below the search box
      // would close it. However, in that case, the Filter menu gets
      // clipped, and we can't see it. So a workaround for now is to
      // check if we click on the dialog element itself, and close the modal
      if (searchDialogRef.current === target) {
        closeSearch();
      }
    }

    searchContainerElement.addEventListener('keydown', onKeydown);
    searchContainerElement.addEventListener('keypress', onKeyEventFocusInput);
    document.addEventListener('click', onClick, { passive: true });

    return () => {
      searchContainerElement.removeEventListener('keydown', onKeydown);
      searchContainerElement.removeEventListener(
        'keypress',
        onKeyEventFocusInput
      );
      document.removeEventListener('click', onClick);
    };
  }, [
    searchContainerRef,
    inputRef,
    searchResultsRef,
    searchDialogRef,
    closeSearch,
    searchFiltersRef,
  ]);

  // eslint-disable-next-line custom-rules/prefer-dex-ui-to-native
  return <div></div>;
};

export { SearchKeyboardManager };
