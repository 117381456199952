import * as React from 'react';
import { SVGProps } from 'react';

const SvgEnter = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4216_24767)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.58594L0.585786 7.00015L5 11.4144L6.41421 10.0002L4.41421 8.00015L10 8.00015L10 6.00015L4.41421 6.00015L6.41422 4.00015L5 2.58594Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M7 7H10C10.5523 7 11 6.55228 11 6V0"
      stroke="currentColor"
      strokeWidth="2"
    />
    <defs>
      <clipPath id="clip0_4216_24767">
        <rect
          width="12"
          height="7"
          rx="3.5"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
          fill="transparent"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEnter;
