import { SmallChevronRight as BreadcrumbChevron } from '@squareup/dex-icons/dex/control';
import { SearchRecord } from '@squareup/dex-types-shared-search';
import { ChildrenProp } from '@squareup/dex-types-shared-ui';
import { Box, Paragraph10, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { MarketTooltip } from '@squareup/dex-ui-shared-market';
import { stripMarkdown } from '@squareup/dex-utils-text';
import clsx from 'clsx';
import React, {
  DOMAttributes,
  FC,
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { SearchResult } from '../../engine/base/search-engine';

import styles from './search-item.module.css';

type SearchLinkComponent = FC<
  { href: string; trackingId: string } & ChildrenProp
>;

interface SearchItemProps {
  record: SearchRecord;
  rank: number;
  onResultClicked: () => void;
  // By default, the first result is always given a "focus"
  // style when you haven't focused on any before.
  // However, if the user focused with mouse or keyboard on another item,
  // we will then show this item in the unfocused style, done through CSS.
  // Therefore is to show a default hover on the first result
  // unless otherwise manipulated.
  applyFocusStyleIfNoneFocused?: boolean | undefined;
  // An injectable LinkComponent. Mainly used if the framework like
  // nextjs wants to run client-side routing. If not available, use
  // a normal anchor tag.
  LinkComponent?: SearchLinkComponent | undefined;
}

const SearchItemAnchor: FC<
  ChildrenProp &
    Pick<SearchItemProps, 'applyFocusStyleIfNoneFocused'> &
    Pick<SearchItemProps, 'rank'> &
    Pick<DOMAttributes<HTMLAnchorElement>, 'onClick'> & {
      href?: string | undefined;
    }
> = React.forwardRef(
  ({ children, applyFocusStyleIfNoneFocused, href, rank, onClick }, ref) => {
    return (
      <Box
        as={'a'}
        className={clsx(
          styles.item,
          applyFocusStyleIfNoneFocused && styles['try-focus']
        )}
        padding={{
          horizontal: '2x',
          vertical: '1.5x',
        }}
        ref={ref}
        href={href}
        // Must pass the onClick because next/link uses it for client-side navigation!
        onClick={onClick}
        data-tracking-id="search-item"
        data-testid="search-item"
        data-tracking-extra={JSON.stringify({ rank, href })}
        margin={{ horizontal: '2x' }}
        border={{ radius: 'standard' }}
      >
        {children}
      </Box>
    );
  }
);
SearchItemAnchor.displayName = 'SearchItemAnchor';

const LinkWrapper: FC<ChildrenProp & SearchItemProps> = ({
  children,
  record,
  rank,
  onResultClicked,
  applyFocusStyleIfNoneFocused,
  LinkComponent,
}) => {
  return LinkComponent ? (
    <LinkComponent trackingId={'search-item'} href={record.url}>
      <SearchItemAnchor
        rank={rank}
        onClick={onResultClicked}
        applyFocusStyleIfNoneFocused={applyFocusStyleIfNoneFocused}
      >
        {children}
      </SearchItemAnchor>
    </LinkComponent>
  ) : (
    <SearchItemAnchor
      rank={rank}
      onClick={onResultClicked}
      applyFocusStyleIfNoneFocused={applyFocusStyleIfNoneFocused}
      href={record.url}
    >
      {children}
    </SearchItemAnchor>
  );
};
LinkWrapper.displayName = 'LinkWrapper';

const SearchItem: FunctionComponent<SearchItemProps> = ({
  record,
  rank,
  onResultClicked,
  applyFocusStyleIfNoneFocused,
  LinkComponent,
}) => {
  const [isClamped, setIsClamped] = useState(false);
  const nameRef = useRef<HTMLElement>(null);

  useEffect(() => {
    let rafId: undefined | number = undefined;

    // MarketTooltip doesn't render immediately. So we must observe and wait for the name to actually
    // appear before we measure it :(

    // TODO: Use a timeout to unwind current event loop before measuring.
    const timeoutId = setTimeout(() => {
      // Use a RAF to wait for next frame. That should give the market tooltip time to paint itself.
      rafId = requestAnimationFrame(() => {
        if (nameRef.current) {
          setIsClamped(
            nameRef.current.scrollHeight > nameRef.current.clientHeight
          );
        }
      });
    });

    return () => {
      clearTimeout(timeoutId);
      if (rafId !== undefined) {
        cancelAnimationFrame(rafId);
      }
    };
  });

  const nameComponent = useMemo(() => {
    return (
      <Paragraph20
        slot="trigger"
        ref={nameRef}
        className={styles.name}
        weight={'semi-bold'}
      >
        {record.name}
      </Paragraph20>
    ) as ReactNode;
  }, [record.name]);

  const nameTooltip = useMemo(() => {
    return isClamped ? (
      <MarketTooltip
        popoverPlacement={'top'}
        popoverDistance={8}
        popoverSkidding={-4}
      >
        {nameComponent}
        <Box as="span" slot="content">
          {record.name}
        </Box>
      </MarketTooltip>
    ) : (
      nameComponent
    );
  }, [record.name, nameComponent, isClamped]);
  const Breadcrumb = ({ record }: SearchResult) => {
    let parents = record.parents || [];
    if (parents.length === 0) {
      return null;
    }
    // Limit bread crumbs to at most three parents
    if (parents.length > 3) {
      parents = parents.slice(0, 3);
    }

    return (
      <Paragraph10
        margin={{ top: '0.5x' }}
        className={styles.breadcrumb}
        data-testid="search-breadcrumb"
        colorVariant="20"
      >
        {parents.map((parent, index) => (
          <span key={parent}>
            {index > 0 && (
              <BreadcrumbChevron
                className={styles['breadcrumb-chevron']}
                style={{ marginLeft: '4px', marginRight: '4px' }}
              />
            )}
            {parent}
          </span>
        ))}
      </Paragraph10>
    );
  };

  return (
    <LinkWrapper
      record={record}
      rank={rank}
      onResultClicked={onResultClicked}
      applyFocusStyleIfNoneFocused={applyFocusStyleIfNoneFocused}
      LinkComponent={LinkComponent}
    >
      <Box className={styles['text-container']}>
        <Box className={styles.title}>
          {nameTooltip}
          <Paragraph10
            className={styles.domain}
            margin={{ left: '0.5x' }}
            weight={'medium'}
            colorVariant={'20'}
          >
            {record.domain}
          </Paragraph10>
        </Box>
        {record.description && (
          <Paragraph20 className={styles.description} colorVariant={'20'}>
            {stripMarkdown(record.description)}
          </Paragraph20>
        )}
        <Breadcrumb key={record.id} record={record} />
      </Box>
      <Box className={styles.category}>
        <Paragraph10
          className={styles.badge}
          weight={'medium'}
          padding={{ horizontal: '0.5x' }}
          border={{ radius: '4' }}
          colorVariant={'20'}
        >
          {record.display_category}
        </Paragraph10>
      </Box>
    </LinkWrapper>
  );
};

export { SearchItem, type SearchLinkComponent };
