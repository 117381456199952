import * as React from 'react';
import { SVGProps } from 'react';

const SvgChevronDown = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4 4.586 4 4 4-4L13.414 6 8 11.414 2.586 6 4 4.586Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgChevronDown;
