import SvgMagnifyingGlass from '@squareup/dex-icons/market/action/MagnifyingGlass';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { setLocalAndForwardedRef } from '@squareup/dex-utils-dom';
import clsx from 'clsx';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import styles from './search-input.module.css';

const inputAnimationFadeInMs = 250;

interface SearchInputProps {
  onInputChanged: (value: string) => void;
  inputValue: string;
}

const SearchInput = React.forwardRef<
  HTMLInputElement,
  SearchInputProps & NullableClassName
>(({ inputValue, onInputChanged, className }, ref) => {
  const inputRef = useRef<HTMLInputElement>();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onInputChanged(e.target.value);
    },
    [onInputChanged]
  );

  useEffect(() => {
    // Wait until its in the market-context, otherwise the focus
    // will fail
    let handle: number | undefined = undefined;
    function tryFocusIfInContextManager() {
      if (inputRef.current?.closest('market-context')) {
        // It runs on a ~0.25s fade in. Focus after the animation
        setTimeout(() => {
          inputRef.current?.focus();
        }, inputAnimationFadeInMs);
      } else {
        handle = requestAnimationFrame(tryFocusIfInContextManager);
      }
    }

    handle = requestAnimationFrame(tryFocusIfInContextManager);

    return () => {
      if (handle !== undefined) {
        cancelAnimationFrame(handle);
      }
    };
  }, []);

  return (
    <Box className={clsx(styles.container, className)}>
      <SvgMagnifyingGlass className={commonIconStyles['icon-color']} />
      <Box<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>
        role="searchbox"
        data-testid="search-input"
        ref={setLocalAndForwardedRef<HTMLInputElement>(inputRef, ref)}
        margin={{ left: '1x' }}
        as={'input'}
        className={styles.input}
        placeholder="Search all developer resources..."
        value={inputValue}
        onChange={onChange}
      ></Box>
    </Box>
  );
});
SearchInput.displayName = 'SearchInput';

export { SearchInput };
