import { SearchRecord } from '@squareup/dex-types-shared-search';

import { SearchDomain } from '../../search-types';

interface SearchResult {
  record: SearchRecord;
}

interface SearchQueryOptions {
  categoryFilter?: string[];
  domain?: SearchDomain;
}

abstract class ISearchEngine {
  abstract query(
    query: string,
    options?: SearchQueryOptions
  ): Promise<SearchResult[]>;
  abstract initialized: boolean;

  // Returns the filterable categories that we want to search the engine for
  abstract getCategories(): string[];

  // Returns the logged-in user's recently clicked results
  abstract getRecentResults(): Promise<SearchResult[]>;

  // Returns popular or recommended results
  abstract getPopularResults(): Promise<SearchResult[]>;

  // Adds the given SearchResult to the list of recent results for the logged-in user
  abstract addRecentResult(result: SearchResult): Promise<void>;
}

export { ISearchEngine, type SearchQueryOptions, type SearchResult };
