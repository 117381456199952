import React, { ForwardedRef, useEffect, useRef, useState } from 'react';

interface SvelteClientSideComponentBase {
  $destroy: () => void;
}

export type SvelteClientSideComponentConstructor<T, P> = new (_arg: {
  hydrate: boolean;
  props: P;
  target: Element | null;
}) => T & SvelteClientSideComponentBase;

type SvelteComponentProps<
  // eslint-disable-next-line no-use-before-define
  T extends SvelteClientSideComponentConstructor<T, P>,
  P
> = {
  clientSideComponent: T;
  componentProps: P;
  serverSideComponent: {
    render: (_props: P) => {
      html: string;
      css: string;
      head: string;
    };
  };
};

const SvelteComponent = React.forwardRef(
  // eslint-disable-next-line no-use-before-define
  <T extends SvelteClientSideComponentConstructor<T, P>, P>(
    props: SvelteComponentProps<T, P>,
    ref: ForwardedRef<T>
  ) => {
    const container = useRef(null);
    const element = useRef<null | (T & SvelteClientSideComponentBase)>(null);

    const [ssrMarkup] = useState<{ __html: string } | undefined>({
      __html: props.serverSideComponent.render(props.componentProps).html,
    });

    useEffect(() => {
      // We always keep the SSR state inlined. This ensures we hydrate the DOM correctly without
      // removing anything important
      element.current = new props.clientSideComponent({
        hydrate: true,
        props: props.componentProps,
        target: container.current,
      });

      // Set the ref to be the Svelte client side component
      if (ref) {
        if (typeof ref === 'function') {
          ref(element.current);
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = element.current;
        }
      }

      return () => {
        // Ensure we call Svelte's `$destroy` hook, otherwise the `onDestroy` hooks
        // won't fire in the component - https://svelte.dev/docs#run-time-client-side-component-api-$destroy
        element.current?.$destroy();
      };
    }, [
      container,
      element,
      props.clientSideComponent,
      props.componentProps,
      ref,
    ]);

    return <div ref={container} dangerouslySetInnerHTML={ssrMarkup}></div>;
  }
);

SvelteComponent.displayName = 'SvelteComponent';

export { SvelteComponent };
